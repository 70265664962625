.layout {
  display: flex;
  min-width: 1280px;
  height: 100vh;
  overflow-y: hidden;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.main-content {
  overflow-y: auto;
}

.main-content--user {
  height: calc(100vh - var(--main-header-height));
}

.main-content--coach {
  height: calc(100vh - var(--main-header-height));
}
