.flex {
  display: flex;
  justify-content: var(--justify-content);
  align-items: var(--align-items);
  flex-direction: var(--flex-direction);
  flex-wrap: var(--flex-wrap);
  width: var(--width);
  height: var(--height);
  gap: var(--gap);
  pointer-events: auto;
}

.flex-item {
  align-self: var(--align-self);
  flex-basis: var(--flex-basis);
  flex-grow: var(--flex-grow);
  flex-shrink: var(--flex-shrink);
  width: var(--width);
  height: var(--height);
  overflow: var(--overflow);
}
