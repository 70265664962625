.sortable-ghost {
  position: relative;
}
.sortable-ghost::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bgCompSecondaryBig);
  border-radius: var(--border-radius);
}

.sortable-chosen {
  border-radius: var(--border-radius);
  overflow: hidden;
  background-color: var(--white);
  cursor: grab;
}
