.hos-text-editor__wrapper {
  font-size: var(--font-size-medium);
}

.hos-text-editor__container {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  color: var(--fgPrimary);
  outline: none;
  overflow-y: auto;
  height: 100%;
  position: relative;
  font-size: var(--font-size-medium);
}

.hos-text-editor__content {
  outline: none;
  padding: 12px 15px;
  overflow-y: auto;
  height: 100%;
  position: relative;
}

.hos-text-editor__toolbar {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  color: var(--fgPrimary);
  border: var(--border-secondary);
}

.hos-text-editor__placeholder {
  position: absolute;
  top: 13px;
  left: 13px;
  color: var(--gray200);
  pointer-events: none;
  font-style: italic;
}

.hos-basic-text-editor__content {
  height: 100%;
  width: 100%;
  outline: none;
  padding: 12px 12px;
  overflow-y: auto;
  position: relative;
}
