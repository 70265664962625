.hos-text-editor__content {
  ul,
  ol,
  p {
    margin-bottom: 0.5rem;
  }
}
.hos-text-editor__textBold {
  font-weight: bold;
}
.hos-text-editor__textItalic {
  font-style: italic;
}
.hos-text-editor__textStrikethrough {
  text-decoration: line-through;
}
.hos-text-editor__textUnderline {
  text-decoration: underline;
  text-decoration-color: black;
}
.hos-text-editor__ol1 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-position: inside;
}
.hos-text-editor__ol2 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-position: inside;
}
.hos-text-editor__ol3 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-position: inside;
}
.hos-text-editor__ol4 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-position: inside;
}
.hos-text-editor__ol5 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-position: inside;
}
.hos-text-editor__ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-position: inside;
}
.hos-text-editor__listItem {
  margin: 0 20px;
  list-style: inherit;
}
.hos-text-editor__listItemChecked,
.hos-text-editor__listItemUnchecked {
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 24px;
  padding-right: 24px;
  list-style-type: none;
  outline: none;
}
.hos-text-editor__listItemChecked {
  text-decoration: line-through;
}
.hos-text-editor__listItemUnchecked:before,
.hos-text-editor__listItemChecked:before {
  content: '';
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0;
  cursor: pointer;
  display: block;
  background-size: cover;
  position: absolute;
}
.hos-text-editor__listItemUnchecked[dir='rtl']:before,
.hos-text-editor__listItemChecked[dir='rtl']:before {
  left: auto;
  right: 0;
}
.hos-text-editor__listItemUnchecked:focus:before,
.hos-text-editor__listItemChecked:focus:before {
  box-shadow: 0 0 0 2px #a6cdfe;
  border-radius: 2px;
}
.hos-text-editor__listItemUnchecked:before {
  border: 1px solid #999;
  border-radius: 2px;
}
.hos-text-editor__listItemChecked:before {
  border: 1px solid rgb(61, 135, 245);
  border-radius: 2px;
  background-color: #3d87f5;
  background-repeat: no-repeat;
}
.hos-text-editor__listItemChecked:after {
  content: '';
  cursor: pointer;
  border-color: #fff;
  border-style: solid;
  position: absolute;
  display: block;
  top: 6px;
  width: 3px;
  left: 7px;
  height: 6px;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
}
.hos-text-editor__nestedListItem {
  list-style-type: none;
}
.hos-text-editor__nestedListItem:before,
.hos-text-editor__nestedListItem:after {
  display: none;
}
.hos-text-editor__heading-h1 {
  font-size: 2em;
}
.hos-text-editor__heading-h2 {
  font-size: 1.5em;
}
.hos-text-editor-content {
  code {
    background-color: rgb(240, 242, 245);
    padding: 1px 0.25rem;
    font-family: Menlo, Consolas, Monaco, monospace;
    font-size: 94%;
  }
}
.hos-text-editor__link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}
.hos-text-editor__link:hover {
  text-decoration: underline;
  cursor: pointer;
}
