/*
  0.75rem=12px
  1rem=16px
  1.125rem=18px
  1.25rem=20px
  1.5rem=24px
  1.75rem=28px
  3.75rem=60px
  5rem=80px
  17.5rem=280px
*/
:root {
  /* DIMENSIONS */
  --assessment-card-width: 7.55rem;
  --assessment-card-gap: 3rem;
  --sidebar-width-collapsed: 4.75rem;
  --sidebar-width: 15.75rem;
  --sidebar-width-lg: 22.75rem;
  --main-header-height: 3rem;
  --logo-header-height: 6.3125rem;
  --modal-width-sm: 30rem;
  --modal-width-md: 38.875rem;
  --modal-width-lg: 46rem;
  --tab-header-height: 3rem;
  --tab-height: 23.25rem;
  --tab-content-height: calc(var(--tab-height) - var(--tab-header-height));
  --tabs-panel-height: calc(100% - 4.5rem);
  --bottom-fixed-area-height: 3rem;

  /* COLORS*/
  --black: #000000;
  --white: #ffffff;
  /* GRAY */
  --gray00: #2c2f48;
  --gray10: #f6f8fa;
  --gray25: #f2f2f7;
  --gray50: #e5e5ea;
  --gray100: #d5d5da;
  --gray200: #bcbdc4;
  --gray300: #a2a3ae;
  --gray400: #888b99;
  --gray500: #6d7081;
  --gray700: #292e43;
  --gray800: #212336;
  --gray900: #161824;

  --whiteAlpha30: #ffffff30;

  /* SPACING */
  --spacing00: 0.125rem;
  --spacing01: 0.25rem;
  --spacing02: 0.5rem;
  --spacing03: 0.75rem;
  --spacing04: 1rem;
  --spacing05: 1.25rem;
  --spacing06: 1.5rem;
  --spacing07: 2rem;
  --spacing08: 2.5rem;
  --spacing09: 3rem;
  --spacing12: 5rem;

  --font-family: 'Inter', sans-serif;

  /* FONT SIZE */
  --font-size-small: 0.75rem;
  --font-size-medium: 0.875rem;
  --font-size-large: 1rem;
  --font-size-xl: 1.5rem;
  --font-size-xxl: 2.25rem;

  /* FONT WEIGHT */
  --font400: 400;
  --font500: 500;
  --font600: 600;
  --font700: 700;

  /* LINE HEIGHT */
  --line-height-small: 1rem;
  --line-height-medium: 1.25rem;
  --line-height-large: 1.5rem;
  --line-height-xl: 2rem;
  --line-height-xxl: 2.75rem;

  /* BOX SHADOWS */
  --section-box-shadow-color: rgba(0, 0, 0, 0.05);
  --button-box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  --popper-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  --dropdown-box-shadow: 0 0.125rem 0.5rem rgba(33, 37, 41, 0.05);
  --tooltip-shadow: drop-shadow(0px 2px 8px rgba(33, 37, 41, 0.05));

  /* BORDERS */
  --border-secondary: 1px solid var(--brdSecondary);
  --input-border: 1px solid var(--fgSecondary);

  /* PADDING */
  --input-padding: var(--spacing02) var(--spacing03);
  --input-icon-padding: var(--spacing02) var(--spacing03) var(--spacing02)
    calc(var(--spacing01) + var(--spacing07));

  /* BORDER RADIUS */
  --border-radius: 0.5rem;
  --border-radius2: 0.125rem;
  --border-radius4: 0.25rem;
  --border-radius8: 0.5rem;
  --border-radius12: 0.75rem;
  --border-radius16: 1rem;

  /* TRANSITIONS */
  --transition-background-color: background-color 0.3s;
}

/*
  For more detailed information on color usage,
   refer to the documentation at:
   https://www.notion.so/hosinc/Color-Definitions-e4b00df4ab6643909f7e89ecee2c9597

   Notes:
   - Ensure adherence to the design and style guidelines
     outlined in the documentation for a consistent app appearance.
   - When changing colors, check for compatibility with the overall theme
     and impact on other interface elements.
*/

[data-theme='light'] {
  /* CORE */
  --accent: #3f7bf3;

  /* LAYOUT BACKGROUND */
  --bgPrimary: #ffffff;
  --bgSecondary: #f2f2f7;
  --bgTertiary: #f8f9fd;

  /* COMPONENTS BACKGROUND */
  --bgCompPrimary: #ffffff;
  --bgCompPrimaryInverse: #17181c;

  --bgCompSecondaryTiny: #66739933;
  --bgCompSecondaryMid: #6673991f;
  --bgCompSecondaryBig: #6673990f;

  --bgCompAccent: #3f7bf3;
  --bgCompMinorAccent: #d9e5fd;
  --bgCompHoverAccent: #326de3;

  --bgCompSuccess: #39ac6f;
  --bgCompMinorSuccess: #d1f0df;

  --bgCompWarning: #eeba2a;
  --bgCompMinorWarning: #faeabf;

  --bgCompDanger: #ee4946;
  --bgCompMinorDanger: #fcdada;

  /*Foreground*/
  --fgPrimary: #000000;
  --fgPrimaryInverse: #ffffff;
  --fgSecondary: #393d4699;
  --fgTertiary: #393d464d;
  --fgInteractive: #0f56e6;
  --fgSuccess: #2e8a59;
  --fgWarning: #d09d11;
  --fgDanger: #e01815;

  /* LIFE DIMENSIONS */
  --lifeDimsCareer: #129ade;
  --lifeDimsMinorCareer: #cdebfa;

  --lifeDimsEnjoyment: #ea8c2e;
  --lifeDimsMinorEnjoyment: #fae3cd;

  --lifeDimsHealth: #eb4770;
  --lifeDimsMinorHealth: #facdd8;

  --lifeDimsRelationships: #7950f1;
  --lifeDimsMinorRelationships: #d8cdfa;

  --lifeDimsFinances: #2caf9a;
  --lifeDimsMinorFinances: #cdfaf3;

  --lifeDimsImpact: #cf47eb;
  --lifeDimsMinorImpact: #f2cdfa;

  --lifeDimsSpirituality: #eeba2a;
  --lifeDimsMinorSpirituality: #faeecd;

  /* DIVIDER */
  --divPrimary: #6673991f;

  /* BORDER */
  --brdPrimary: #17181c;
  --brdSecondary: #66739933;
  --brdAccent: #3f7bf3;

  --brdSuccess: var(--bgCompSuccess);
  --brdDanger: #eb2c28;

  --border-secondary: 1px solid var(--brdSecondary);

  /* OVERLAY */
  --ovlPrimary: #17181c66;
}

[data-theme='dark'] {
  /* CORE */
  --accent: #2267f1;

  /* LAYOUT BACKGROUND */
  --bgPrimary: #17181c;
  --bgSecondary: #000000;
  --bgTertiary: #202227;

  /*COMPONENT BACKGROUND */
  --bgCompPrimary: #3a4053;
  --bgCompPrimaryInverse: #ffffff;

  --bgCompSecondaryTiny: #73798c66;
  --bgCompSecondaryMid: #73798c3d;
  --bgCompSecondaryBig: #66739940;

  --bgCompAccent: #2267f1;
  --bgCompMinorAccent: #062056;
  --bgCompHoverAccent: #1e5cd9;

  --bgCompSuccess: #349b64;
  --bgCompMinorSuccess: #1e3428;

  --bgCompWarning: #e9b013;
  --bgCompMinorWarning: #4e3b06;

  --bgCompDanger: #eb2c28;
  --bgCompMinorDanger: #3b2626;

  /* FOREGROUND */
  --fgPrimary: #ffffff;
  --fgPrimaryInverse: #000000;
  --fgSecondary: #ffffff99;
  --fgTertiary: #ffffff4d;
  --fgInteractive: #5288f4;
  --fgSuccess: #42c07d;
  --fgWarning: #f0c140;
  --fgDanger: #ef5a57;

  /* LIFE DIMENSIONS */
  --lifeDimsCareer: #129ade;
  --lifeDimsMinorCareer: #08354d;

  --lifeDimsEnjoyment: #ea8c2e;
  --lifeDimsMinorEnjoyment: #4a2d10;

  --lifeDimsHealth: #eb4770;
  --lifeDimsMinorHealth: #4d1724;

  --lifeDimsRelationships: #7950f1;
  --lifeDimsMinorRelationships: #2a1d52;

  --lifeDimsFinances: #2caf9a;
  --lifeDimsMinorFinances: #11453d;

  --lifeDimsImpact: #cf47eb;
  --lifeDimsMinorImpact: #43184d;

  --lifeDimsSpirituality: #eeba2a;
  --lifeDimsMinorSpirituality: #4a3a0f;

  /* DIVIDER */
  --divPrimary: #73798c3d;

  /* BORDER */
  --brdPrimary: #ffffff;
  --brdSecondary: #73798c66;
  --brdAccent: #3f7bf3;

  --brdSuccess: #39ac6f;
  --brdDanger: #eb2c28;

  --border-secondary: 1px solid var(--brdSecondary);

  /* OVERLAY */
  --ovlPrimary: #17181c66;
}

body {
  font-family: var(--font-family);
  font-weight: 400;
}

dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

img {
  width: 100%;
  height: auto;
}

/* TOASTIFY */

.toast {
  padding: var(--spacing02) !important;
  min-height: auto !important;
  font-family: var(--fontFamily) !important;
  font-size: var(--font-size-small) !important;
  line-height: var(--line-height-small) !important;
  font-weight: var(--font500) !important;
}

.toast-body {
  margin: 0 !important;
  padding: var(--spacing00) var(--spacing01) !important;
}

.toast button {
  border: none;
  color: inherit;
  background-color: transparent;
}
