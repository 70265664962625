.input {
  box-sizing: border-box;
  padding: var(--input-padding);
  border: var(--border-secondary);
  border-radius: var(--border-radius);
  width: 100%;
  color: var(--fgPrimary);
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: var(--font-weight);
  background-color: transparent;
}

.input::placeholder {
  color: var(--fgSecondary);
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: var(--font-weight);
}

.input:focus {
  outline: none;
  border-color: var(--fgPrimary);
  box-shadow: none;
}

.input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  margin-right: 10px;
  height: 10px;
  width: 10px;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg width="7" height="7" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" fill="none"><path d="M11 1L1 11M1 1L11 11" stroke="black" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-size: 10px 10px;
}

.input--error:focus,
.input--error {
  border-color: var(--brdDanger);
}

.input[type='text']:disabled {
  background-color: var(--bgCompSecondaryMid);
}

.label__icon {
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.search__wrap {
  position: relative;
  --input-padding: var(--input-icon-padding);
}

input[type='search']::-webkit-search-decoration:hover,
input[type='search']::-webkit-search-cancel-button:hover {
  cursor: pointer;
}
