.filter {
  position: relative;
}

.filter__list {
  position: absolute;
  top: calc(100% + 4px);
  right: -4px;
  z-index: 2;
  width: 220px;
  background-color: var(--bgPrimary);
  box-shadow: 0 0 5px 0 var(--section-box-shadow-color);
  border-radius: 5px;
  overflow: hidden;
}

.filter__btn {
  border-radius: 8px;
  position: relative;
  z-index: 1;
}

.filter__btn--open {
  background-color: var(--bgSecondary);
}

.filter__btn--active::before {
  content: '';
  display: block;
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 9px);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  z-index: -1;
}

.filter__btn--active > div {
  z-index: 1;
}

.filter__item {
  position: relative;
  display: flex;
  height: 36px;
  align-items: center;
  padding-left: 32px;
  width: 100%;
  justify-content: flex-start;
  background-color: var(--bgPrimary);
  cursor: pointer;
  border-radius: 0;
}

.filter__check-icon {
  position: absolute;
  left: var(--spacing03);
  top: calc(50% - 6px);
}

.filter__item:hover {
  background-color: var(--bgSecondary);
}

.filter__section {
  border-bottom: var(--border-secondary);
}
