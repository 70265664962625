.link-editor {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  max-width: 350px;
  width: 100%;
  opacity: 0;
  background-color: var(--bgSecondary);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  transition: opacity 0.5s;
  will-change: transform;
  padding: 0 5px 0 10px;
}

.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

.link-editor .button.active,
.toolbar .button.active {
  background-color: rgb(223, 232, 250);
}

.link-editor .link-input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 7px 5px 7px 0;
  padding: 8px 10px;
  border-radius: 8px;
  font-size: var(--font-size-medium);
  outline: 0;
  position: relative;
  font-family: inherit;
  background-color: var(--bgPrimary);
}

.link-editor .link-view {
  display: flex;
  gap: 3px;
  align-items: center;
  width: 100%;
  margin: 7px 0px;
  outline: 0;
  position: relative;
  font-family: inherit;
  height: 34px;
}

.link-editor .link-view a {
  display: block;
  word-break: break-word;
  color: var(--fgInteractive);
  cursor: pointer;
  text-decoration: underline;
  overflow: hidden;
  width: calc(100% - 30px);
}

.link-editor .link-input a {
  color: rgb(33, 111, 219);
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
  text-decoration: underline;
}

.link-editor .font-size-wrapper,
.link-editor .font-family-wrapper {
  display: flex;
  margin: 0 4px;
}

.link-editor select {
  padding: 6px;
  border: none;
  background-color: rgba(0, 0, 0, 0.075);
  border-radius: 4px;
}
