svg {
  fill: var(--fill);
  stroke: var(--stroke);
}

[data-react-component='box']:hover:has(svg):not(:has([data-react-component='box']))
  svg {
  fill: var(--hover-fill);
  stroke: var(--hover-stroke);
}
