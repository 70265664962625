.breadcrumbs {
  display: flex;
  margin: 0;
  padding: 0;
}

.breadcrumbs__item {
  color: var(--fgSecondary);
  align-items: center;
  display: flex;
}

.breadcrumbs__link:hover {
  text-decoration: underline;
}

.breadcrumbs__link {
  color: var(--fgPrimary);
  text-decoration: none;
}

.breadcrumbs__item svg {
  margin-left: var(--spacing02);
  margin-right: var(--spacing02);
}
