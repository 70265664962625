.link {
  text-decoration: none;
}

.participants-table {
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
  /* background and border for tbody, can be reused in shared Table component */
  tbody {
    border-radius: var(--border-radius12);
    outline: 1px solid var(--bgCompSecondaryMid);
    td {
      background-color: var(--bgTertiary);
    }
    tr:first-child {
      td:first-child {
        border-top-left-radius: var(--border-radius12);
      }
      td:last-child {
        border-top-right-radius: var(--border-radius12);
      }
    }
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: var(--border-radius12);
      }
      td:last-child {
        border-bottom-right-radius: var(--border-radius12);
      }
    }
  }
}

/* here you can adjust column width if needed */
.participants-table thead {
  th:first-child {
    padding-left: 1rem;
  }
  th:last-child {
    width: 10rem;
  }
}

.participants-table tbody .row:not(:last-child) {
  border-bottom: var(--border-secondary);
}
