.raw-html ul,
.raw-html ol {
  padding-left: 1rem;
  margin: 1rem 0;
}

.raw-html li {
  list-style: inherit;
}

.raw-html p {
  margin-bottom: 0.5rem;
}

.raw-html a {
  color: var(--fgInteractive);
}
.raw-html img {
  max-width: 300px;
}
