.label + input {
  display: none;
}

.label {
  box-sizing: border-box;
  border: 1px solid var(--fgSecondary);
  border-radius: var(--border-radius);
  width: 16.25rem;
  cursor: pointer;
}
