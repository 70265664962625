.user-avatar:hover {
  box-shadow: var(--button-box-shadow);
  border-radius: 50%;
}

.option-button {
  width: 100%;
  display: grid;
  background-color: var(--gray);
}

.option-button:hover {
  background-color: var(--bgCompSecondaryBig);
}
