.challenge-info {
  display: flex;
  margin-right: var(--spacing05);
}

.challenge-info__picture {
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
}
