.card-image {
  max-width: 100%;
  height: auto;
  border-radius: var(--border-radius12);
}

.card-image__preview {
  object-fit: 'cover';
  border-radius: var(--border-radius16);
}
