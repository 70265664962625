.react-datepicker-wrapper {
  width: auto !important;
}

.react-datepicker-popper,
.react-datepicker-popper[data-placement^='bottom'] {
  padding-top: 0.25rem !important;
}

.react-datepicker {
  font-family: inherit;
  background-color: var(--bgCompPrimary);
  border: 1px solid var(--brdSecondary);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  color: var(--fgPrimary);
}

.react-datepicker .react-datepicker__triangle {
  display: none;
}

.react-datepicker .react-datepicker__header {
  background-color: var(--bgCompPrimary);
  padding-top: 0;
  border-bottom: none;
}

.react-datepicker .react-datepicker__day-name {
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  color: var(--fgPrimary);
}

.react-datepicker .react-datepicker__day {
  font-size: var(--font-size-small);
  line-height: 28px;
  text-align: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.react-datepicker
  .react-datepicker__day:not(.react-datepicker__day--disabled):not(.react-datepicker__day--selected) {
  color: var(--fgPrimary);
}

.react-datepicker .react-datepicker__day--disabled {
  color: var(--fgSecondary);
}

.react-datepicker
  .react-datepicker__day--in-range:not(.react-datepicker__day--keyboard-selected):not(.react-datepicker__day--range-end),
.react-datepicker
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start):not(.react-datepicker__day--range-end) {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0 0.166rem;
  background-color: var(--bgCompMinorAccent);
  color: var(--fgPrimary);
}
.react-datepicker
  .react-datepicker__day--outside-month:not(.react-datepicker__day--selected) {
  background-color: var(--bgCompPrimary) !important;
}

.react-datepicker .react-datepicker__day--selected,
.react-datepicker .react-datepicker__day--range-end {
  background-color: var(--bgCompAccent);
  color: var(--white);
  border-radius: 50%;
}

.react-datepicker .react-datepicker__day--keyboard-selected {
  background-color: transparent;
}

.react-datepicker
  .react-datepicker__day:not(.react-datepicker__day--disabled):not(.react-datepicker__day--selected):hover {
  background-color: var(--bgCompSecondaryBig);
  color: var(--fgInteractive);
}

/*time container*/

.react-datepicker__header--time--only {
  display: none;
}

.react-datepicker-time__input {
  color: var(--black);
}

.react-datepicker .react-datepicker__header--time {
  border-bottom: 1px solid var(--brdSecondary);
}

.react-datepicker .react-datepicker-time__header {
  padding-top: 0.5rem;
  font-weight: normal;
  font-size: 0.75rem;
  background-color: var(--bgCompPrimary);
  color: var(--fgPrimary);
}

.react-datepicker .react-datepicker__time-container {
  width: 105px;
}

.react-datepicker__time-box {
  width: 100% !important;
}

.react-datepicker__time-list-item {
  padding-left: 1rem !important;
  background-color: var(--bgCompPrimary);
  text-transform: lowercase;
}

.react-datepicker__time-list-item:not(.react-datepicker__time-list-item--selected):hover {
  background-color: var(--bgSecondary) !important;
  padding: 0 10px;
}

.react-datepicker__time-list-item--selected {
  background-color: var(--bgCompAccent) !important;
  color: var(--fgPrimaryInverse) !important;
  padding: 0 10px;
  font-weight: 400 !important;
}

.react-datepicker__time-list-item--selected::after {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: 8px;
}

.react-datepicker__time-list-item--disabled {
  display: none !important;
}
