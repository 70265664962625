.typography {
  margin: 0;
  padding: 0;
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--line-height);
  text-align: var(--text-align);
  color: var(--color);
  white-space: var(--white-space);
  overflow-wrap: var(--overflow-wrap);
  text-transform: var(--text-transform);
  word-break: var(--word-break);
}

[data-react-component='box']:hover:has(.typography):not(:has([data-react-component='box']))
  .typography {
  color: var(--hover-color);
}

.typography--cropped {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}
