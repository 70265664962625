.text-field,
.replicated-block {
  border: none;
  padding: 0;
  width: 100%;
  min-height: auto;
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  font-weight: var(--font400);
  box-sizing: border-box;
  resize: none;
  background-color: inherit;
  word-break: break-word;
}

.text-field:focus {
  resize: vertical;
}

.text-field--border,
.replicated-block--border {
  border: var(--input-border);
  border-radius: var(--border-radius);
  padding: var(--spacing02) var(--spacing02) var(--spacing02) var(--spacing03);
}

.text-field:focus {
  outline: none;
  border-color: var(--black);
  box-shadow: none;
}

.text-field:disabled {
  background-color: var(--bgCompSecondaryMid);
}

.text-field::placeholder {
  color: var(--fgSecondary);
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
}

.autogrow-wrap {
  display: grid;
}

.autogrow-wrap .text-field {
  resize: none;
  overflow: hidden;
  min-height: auto;
}

.replicated-block {
  white-space: pre-wrap;
  visibility: hidden;
  min-height: auto;
}

.autogrow-wrap .text-field,
.autogrow-wrap .replicated-block {
  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

.autogrow-wrap .text-field {
  height: 100%;
}
