.notification {
  position: absolute;
  bottom: 0;
  right: 0;
  height: calc(100vh - var(--main-header-height));
  max-height: calc(100vh - var(--main-header-height));
  background-color: var(--bgPrimary);
  width: 23rem;
  box-shadow: -2px 0 5px var(--section-box-shadow-color);
}

.notification__content {
  overflow: auto;
  max-height: calc(100% - 3rem);
}

.notification__item {
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  border-radius: 0;
  border-bottom: var(--border-secondary);
}

.notification__item:hover {
  background-color: var(--bgSecondary);
}

.notification__item:first-child {
  border-top: var(--border-secondary);
}

.notification__item--active {
  background-color: var(--bgSecondary);
}

.notification__btn > div {
  border-radius: 8px;
}

.notification__btn--active > div {
  background-color: var(--bgSecondary);
}

.notification__btn-icon {
  position: relative;
}

.notification__btn-icon--unread::after {
  content: '';
  display: block;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: red;
  border: 1px solid var(--white);
}

.notification__btn--active .notification__btn-icon--unread::after {
  border-color: var(--border-secondary);
}

.notification__user-avatar {
  position: relative;
}

.notification__user-avatar--active::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  border: 1px solid var(--white);
}
