.btn {
  border-radius: 0.5rem;
}

.btn--button {
  border: none;
  padding: 0;
  background-color: transparent;
  transition: var(--transition-background-color);
}

.btn--link {
  display: block;
  text-decoration: none;
  overflow: hidden;
}

.btn--critical {
  background-color: var(--bgCompDanger);
  color: white;
}

.btn--primary {
  background-color: var(--bgCompAccent);
  color: white;
}

.btn--tertiary {
  background-color: 'transparent';
}

.btn--primary:hover {
  background-color: var(--bgCompHoverAccent);
}

.btn--primary:disabled {
  background-color: var(--bgCompSecondaryMid);
}

.btn--secondary {
  border: var(--border-secondary);
}

.btn--primary .svg--fill {
  fill: var(--fgPrimaryInverse);
}

.btn--primary .svg--stroke {
  stroke: var(--fgPrimaryInverse);
}

.btn--secondary .svg--fill,
.btn--tertiary .svg--fill {
  fill: var(--fgPrimary);
}

.btn--secondary .svg--stroke,
.btn--tertiary .svg--stroke {
  stroke: var(--fgPrimary);
}

.btn:hover {
  cursor: pointer;
}

.btn:disabled {
  cursor: default;
}

.btn--critical:disabled,
.btn--primary:disabled,
.btn--tertiary:disabled,
.btn--secondary:disabled {
  background-color: var(--bgSecondary);
  cursor: default;
}

.btn--symbol {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn--symbol:hover {
  background-color: var(--bgSecondary);
}

.btn--symbol:active {
  background-color: var(--bgSecondary);
}

.btn--symbol:disabled {
  background-color: transparent;
  opacity: 30%;
}

.btn--tertiary:hover,
.btn--tertiary:disabled {
  background-color: var(--bgSecondary);
}

.btn--tertiary.btn--invert:hover {
  background-color: var(--bgSecondary) !important;
}
